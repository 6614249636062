@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"), url("../fonts/montserrat.woff") format("woff"), url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratbold.woff2") format("woff2"), url("../fonts/montserratbold.woff") format("woff"), url("../fonts/montserratbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
header {
  background-color: #E5E5E5;
}
header.header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 15px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 6px;
}
header .header__burger span:nth-child(3) {
  top: 12px;
}
header .header__logo {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
header .header__nav {
  font-weight: 700;
}
header .header__nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .header__nav ul li {
  position: relative;
  text-align: center;
  margin-left: 50px;
}
header .header__nav ul li:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
header .header__nav ul li.current:before,
header .header__nav ul li:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
main {
  flex: 1;
}
main.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
article {
  flex: 1;
}
aside {
  width: 350px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #000;
  margin: 0 0 15px 25px;
  padding-left: 50px;
}
aside .sidebar-block {
  margin-bottom: 40px;
}
aside .sidebar-block__title {
  display: block;
  background-color: #FDE402;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  padding: 18px 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}
aside .sidebar-block__list {
  list-style-position: outside;
}
aside .sidebar-block__list li {
  margin-bottom: 25px;
}
aside .sidebar-block__list li.current a {
  text-decoration: underline;
}
aside .sidebar-block__list a {
  font-weight: 700;
}
aside .sidebar-block__list a:hover {
  text-decoration: underline;
}
.content {
  margin-bottom: 30px;
}
.content > * {
  margin-bottom: 15px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 24px;
}
.content h2 {
  font-size: 20px;
}
.content h3 {
  font-size: 18px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content blockquote {
  position: relative;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
  padding: 25px 50px;
  font-style: italic;
}
.content blockquote:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 70px;
  height: 70px;
  background-image: url('../img/icon-quote.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.content table {
  width: 100%;
  max-width: 90%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}
.content table td,
.content table th {
  padding: 10px 5px;
  border: 1px solid #cccccc;
}
.content table th {
  background-color: #E5E5E5;
  font-weight: 700;
  text-align: center;
}
footer {
  background-color: #E5E5E5;
}
footer .footer__copyright {
  font-size: 15px;
  text-align: center;
  padding: 20px 0;
}
@media screen and (max-width: 991px) {
  main {
    flex-direction: column;
  }
  aside {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  aside .sidebar-block__list {
    list-style-position: inside;
  }
  aside .sidebar-block__list li {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  header {
    background-color: #FDE402;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(25deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-25deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    background-color: #fff;
    top: -20px;
    left: 50px;
    padding: 30px 15px;
    width: 200px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  header .header__nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  header .header__nav ul li {
    margin: 0 0 15px 0;
  }
  footer {
    background-color: #FDE402;
  }
}
