@media screen and (max-width: 991px) {
  main {
    flex-direction: column;
  }

  aside {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;

    .sidebar-block {
      &__list {
        list-style-position: inside;

        li {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    background-color: @yellow;

    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(25deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-25deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        background-color: #fff;
        top: -20px;
        left: 50px;
        padding: 30px 15px;
        width: 200px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          li {
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }

  footer {
    background-color: @yellow;
  }
}