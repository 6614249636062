@import "fonts";

@gray: #E5E5E5;
@yellow: #FDE402;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  background-color: @gray;

  &.header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }
  .header {
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 15px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 12px;
        }
      }
    }
    &__logo {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &__nav {
      font-weight: 700;

      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
          position: relative;
          text-align: center;
          margin-left: 50px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
          }
          &.current,
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

main {
  flex: 1;

  &.wrap {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
}

article {
  flex: 1;
}

aside {
  width: 350px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #000;
  margin: 0 0 15px 25px;
  padding-left: 50px;

  .sidebar-block {
    margin-bottom: 40px;

    &__title {
      display: block;
      background-color: @yellow;
      text-align: center;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 700;
      padding: 18px 20px;
      margin-bottom: 20px;
      border-radius: 20px;
    }
    &__list {
      list-style-position: outside;

      li {
        margin-bottom: 25px;

        &.current {
          a {
            text-decoration: underline;
          }
        }
      }
      a {
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.content {
  margin-bottom: 30px;

  & > * {
    margin-bottom: 15px;
  }
  
  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  blockquote {
    position: relative;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #F5F5F5;
    padding: 25px 50px;
    font-style: italic;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 70px;
      height: 70px;
      background-image: url('../img/icon-quote.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  table {
    width: 100%;
    max-width: 90%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;

    td, th {
      padding: 10px 5px;
      border: 1px solid darken(@gray, 10%);
    }
    th {
      background-color: @gray;
      font-weight: 700;
      text-align: center;
    }
  }
}

footer {
  background-color: @gray;

  .footer {
    &__copyright {
      font-size: 15px;
      text-align: center;
      padding: 20px 0;
    }
  }
}

@import "media";